/* In your CSS file */
body {
    user-select: none; /* Disable text selection */
  }
  
  /* Optionally, you can disable the context menu as well */
  body {
    user-select: none; /* Disable text selection */
    -webkit-user-select: none; /* Disable text selection on iOS Safari */
    -webkit-touch-callout: none; /* Disable long-press context menu on iOS */
  }
  
  /* Allow text selection within specific elements */
  .allow-selection {
    user-select: text; /* Allow text selection within elements with this class */

  }
  /* In your CSS file */
img {
    user-drag: none; /* Disable image dragging (drag and drop) */
    pointer-events: none; /* Make images non-interactive */
  }
  
  






  figure {
    display: grid;
   
    overflow: hidden;
    cursor: pointer;
  }
  figure > * {
    grid-area: 1/1;
    transition: .4s;
  }
  figure figcaption {
    display: grid;
    align-items: end;
    font-family: sans-serif;
    font-size: 2.3rem;
    font-weight: bold;
    color: #0000;
    padding: .75rem;
    background: var(--c,#0009);
    clip-path: inset(0 var(--_i,100%) 0 0);
    -webkit-mask:
      linear-gradient(#000 0 0),
      linear-gradient(#000 0 0);
    -webkit-mask-composite: xor;
    -webkit-mask-clip: text, padding-box;
    margin: -1px;
  }
  figure img {
height: 100%;
width: 100%;
  }
  figure:hover figcaption{
    --_i: 0%;
  }
  figure:hover img {
    transform: scale(1.2);
  }
  @supports not (-webkit-mask-clip: text) {
    figure figcaption {
     -webkit-mask: none;
     color: #fff;
     color: #fff;
    }
  }
  





































































































  swiper .res-slide {
    min-width: 300px;
    max-width: 400px;
    min-height: 350px;
    border: 2px solid hsl(210, 100%, 50%);
    border-radius: 20px;
  }
  
  .swiper-nav-btns button {
    font-family: 'Joystix Monospace', Arial, sans-serif;
    background-color: #ff0099;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .swiper-nav-btns button:hover {
    background-color: hsl(210, 100%, 40%);
    cursor: pointer;
  }
  