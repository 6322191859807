.hoveranimationoftopnav{
    background-image: linear-gradient(
        to right,
        #ff0099,
        #ff0099 50%,
        #fff 50%
      );
      background-size: 200% 100%;
      background-position: -100%;
      display: inline-block;
margin: 12px !important;
font-size: 22px !important;
      padding: 10px 0;
      color: #fff !important;
      position: relative;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 0.3s ease-in-out;
}


  
.hoveranimationoftopnav:before{
    content: '';
    background: #ff0099;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  .hoveranimationoftopnav:hover {
   background-position: 0;
  }
  
  .hoveranimationoftopnav:hover::before{
    width: 100%;
  }