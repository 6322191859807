@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";
*{
  
  font-family: 'MyCustomFont' !important;
}

body{
    background-color: #000000 !important;
    overflow-x: hidden;
 
    color: #fff !important;
  }
  body {
    cursor: url('./mouse.png'), auto;
}

.hover-element {
    width: 200px;
    height: 200px;
    background-color: lightblue;
    margin: 100px auto;
    text-align: center;
    line-height: 200px;
    font-size: 20px;
}

.hover-element:hover {
    cursor: url('./hover.png'), auto;
}
  @font-face {
    font-family: 'MyCustomFont';
    src: url('./fonts/joystixmonospace.ttf') format('truetype');
  }
  
  #catIframe {
    transform-origin: top center;
    transform: scale(0.8);
    width: 115%;
    margin-top: -5%;
    position: absolute;
    top: 10%;
    background-color: #1f1f1f35;
    left: 50%;
    transform: translateX(-50%) scale(0.8);
}

.pixel-button {
  font-family: "Joystix Monospace";
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 60px !important;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(219deg, var(#411b84) 19%, transparent 19%,transparent 20%, var(#9c1569) 20%, var(#9c1569) 39%, transparent 39%,transparent 40%, var(#db0860) 40%,var(#db0860) 59% , transparent 59%,transparent 60%, var(#fa0454) 60%, var(#fa0454) 79%, transparent 79%, transparent 80%, var(#fa0454) 80%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
text-decoration:none !important;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 40px;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
}

.linear-wipe {
  text-align: center;
  
  background: linear-gradient(to right, #f6e500 20%, #ff0099 40%,  #ff0099 60%, #FFF 80%);
  background-size: 90% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  animation: shine 1s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

.welcometop{
  text-align: center !important;
  color: #7b7b7b;
  font-size: 1em;

}



.main-title {
  font-size: 2em;
  color: #ff0099;
  text-align: center;
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
}


.sub-text {
  color: white;
  margin-top: 20px;
  text-align: center;
}





.learn-more-btn {
  font-family: 'Joystix Monospace', Arial, sans-serif;
  background-color: #ff0099;
  color: white;
  padding: 12px 20px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s ease;
}



@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 1px, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 1px, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-2px, 1px, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 1px, 0);
}
}


.centeritall{
  justify-content: center;
  align-items: center;
  text-align: center;
}
.centeritallt{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: grid;
}
.youtube-icon  {
  display: inline-block;
  background-color: white;
  color: black;
  padding: 30px;
  margin: 0 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
}


/* Define default link style */
.link {
  color: #fff;
  text-decoration: none; /* Remove default underline */
}

/* Define active link style */
.link.active {
  color: #ff0099;
}

.video-container {
  margin-top: 20px;
  padding: 5px;
  border: 3px solid #ff0099;
  border-radius: 10px;
  box-shadow: 0 0 10px #ff0099;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}
.video-container iframe {
  width: 100%;
  height: 315px;
  border-radius: 7px;
}

.about-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}


.about-logo img {
    max-width: 50%;
    height: auto;
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -ms-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
}


.about-title {
  color: #ff0099;
  font-size: 1.5em;
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  
}

.about-list {
  color: white;
  list-style-position: inside;
  padding-left: 20px;
}
li{
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}





.centeronmobile{
  justify-content: center;
  align-items: center;
  text-align: center;
}


.roadmap-title {
  color: #ff0099;
  font-size: 2em;
}



.info-title_under{
  font-family: 'Poppins', sans-serif !important;
}
.info-title_underc{
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px;
}
.info-title_under_white{
font-size: 12px;
}


.roadmap-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  max-width: 900px;
  margin: auto;
}




.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin-bottom: 30px;
}


.icon-container img, .icon-container iframe {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  border-radius: 10px;
  margin: 0 auto;
}

.icon-container p {
  color: #ff0099;
  font-size: 1em;
  margin-top: 10px;
}

.about-aztec-title {
  color: white;
  font-size: 2em;
  margin-bottom: 30px;
  word-wrap: break-word;
}


.aztec-subsection img {
  max-width: 100%;
  height: auto;
  max-height: 300px;
}

.aztec-subheading {
  color: #ff0099;
  font-size: 1.5em;
  margin: 10px 0;
}
.aztec-text {
  color: white;
  word-wrap: break-word;
}
.faq-item {
  margin-bottom: 40px;

}



.faq-gif {
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
  transition: transform 0.5s ease !important;
}


.faq-subheading {
  color: #ff0099;
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}


.faq-text {
  color: white;
  text-align: justify;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}


.faq-button {
  font-family: 'Joystix Monospace', Arial, sans-serif;
  background-color: #ff0099;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.75em;
  color: white;
  text-align: center;
}

.footer-bottom .highlight, .footer-bottom a {
  color: #ff0099;
  text-decoration: none;
}

.headtext_footer_pages{
  color: #ff0099;
  text-align: center;
  font-weight: 500;
  font-size: 20px;

}
.tophead_info {
  color: #54595f;
text-align: center;
  font-size: 14px;
}

.main-title_info{
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  color: #ff0099;
  font-size: 30px;
  text-align: center;
}
.main-title_infot{
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  color: #ff0099;
  font-size: 30px;
  text-align: center;
}


.cybr-btn {
  --primary: hsl(314, 75%, 50%);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 0;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var( #ff0099), 90%, 60%);
  --clip: polygon(0 0, 100% 0, 100% 100%, 95% 100%, 95% 90%, 85% 90%, 85% 100%, 8% 100%, 0 70%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(0 2%, 100% 2%, 100% 95%, 95% 95%, 95% 90%, 85% 90%, 85% 95%, 8% 95%, 0 70%);
  --clip-two: polygon(0 78%, 100% 78%, 100% 100%, 95% 100%, 95% 90%, 85% 90%, 85% 100%, 8% 100%, 0 78%);
  --clip-three: polygon(0 44%, 100% 44%, 100% 54%, 95% 54%, 95% 54%, 85% 54%, 85% 54%, 8% 54%, 0 54%);
  --clip-four: polygon(0 0, 100% 0, 100% 0, 95% 0, 95% 0, 85% 0, 85% 0, 8% 0, 0 0);
  --clip-five: polygon(0 0, 100% 0, 100% 0, 95% 0, 95% 0, 85% 0, 85% 0, 8% 0, 0 0);
  --clip-six: polygon(0 40%, 100% 40%, 100% 85%, 95% 85%, 95% 85%, 85% 85%, 85% 85%, 8% 85%, 0 70%);
  --clip-seven: polygon(0 63%, 100% 63%, 100% 80%, 95% 80%, 95% 80%, 85% 80%, 85% 80%, 8% 80%, 0 70%);
  font-family: 'Cyber', sans-serif;
  color: var(--color);
  cursor: pointer;
  background-color: #ff0099;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 300px;
  height: 75px;
  line-height: 75px;
  transition: background 0.2s;
}

.cybr-btn:hover {
  --primary: hsl(var(--primary-hue), 85%, calc(var(--primary-lightness, 50) * 0.8%));
}
.cybr-btn:active {
  --primary: hsl(var(--primary-hue), 85%, calc(var(--primary-lightness, 50) * 0.6%));
}

.cybr-btn:after,
.cybr-btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: var(--clip);
  z-index: -1;
}

.cybr-btn:before {
  background: var(--shadow-primary);
  transform: translate(var(--border), 0);
}

.cybr-btn:after {
  background: var(--primary);
}

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: hsl(0, 0%, 0%);
  font-size: var(--label-size);
}

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  clip-path: var(--clip);
  animation: glitch 2s infinite;
  display: none;
}

.cybr-btn:hover .cybr-btn__glitch {
  display: block;
}

.cybr-btn__glitch:before {
  content: '';
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  clip-path: var(--clip);
  background: var(--primary);
  z-index: -1;
}

@keyframes glitch {
  0% {
    clip-path: var(--clip-one);
  }
  2%, 8% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }
  6% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  9% {
    clip-path: var(--clip-two);
    transform: translate(0, 0);
  }
  10% {
    clip-path: var(--clip-three);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  13% {
    clip-path: var(--clip-three);
    transform: translate(0, 0);
  }
  14%, 21% {
    clip-path: var(--clip-four);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  25% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  30% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }
  35%, 45% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * -1%));
  }
  40% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * 1%));
  }
  50% {
    clip-path: var(--clip-six);
    transform: translate(0, 0);
  }
  55% {
    clip-path: var(--clip-seven);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  60% {
    clip-path: var(--clip-seven);
    transform: translate(0, 0);
  }
  31%, 61%, 100% {
    clip-path: var(--clip-four);
  }
}

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260;
}


.recent_posts{
  color: rgba(78, 51, 76, 0.652);
  font-size: 15px;
}
.blog_post_links{
  color: #ff0099;
  text-decoration: none !important;
  
}


.blog_post_links:hover{
  color: #585858;
}
















.main_image{
  width: 100%;
}








@media screen and (min-width: 1000px){

  .main_image{
    width: 100%;
  }

  

  .main-title {
    font-size: 2.5em;
    color: #ff0099;
    text-align: center;
    text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -ms-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
  }
  

  

.centeronmobile{
  justify-content: start;
  align-items: start;
  text-align: start;
}
.roadmap-title {
  color: #ff0099;
  font-size: 2.5em;
}

.info-title {
  color: #ff0099;
  font-size: 1.6em;
}
.about-title {
  color: #ff0099;
  font-size: 2em;
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  
}
.main-title_info{
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  color: #ff0099;
  font-size: 70px;
  text-align: center;
}
.main-title_infot{
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  color: #ff0099;
  font-size: 40px;
  text-align: center;
}
.info-title_under_white{
  font-size: 15px;
  }
}










































.back_of_sponser{
  background-color: transparent;
  background: linear-gradient(43deg, rgba(44, 44, 45, 0.605) 0%, rgba(42, 42, 42, 0.556) 46%, rgba(59, 59, 59, 0.562) 100%);
}








.myCard {
  background-color: transparent;
  width:360px;
  height: 454px;
  perspective: 1000px;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.innerCard {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 2s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.myCard:hover .innerCard {
  transform: rotateY(360deg);
}

.frontSide,
.backSide {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  color: white;
  box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.992);
  font-weight: 700;
}

.frontSide,
.frontSide::before {
  background: linear-gradient(43deg, rgba(248, 249, 255, 0.605) 0%, rgba(246, 243, 245, 0.556) 46%, rgba(240, 239, 237, 0.562) 100%);
}

.backSide,
.backSide::before {
  background-image: url('./fire.gif');

  background-size: cover; /* Ensure the background image covers the entire element */
}

.backSide {
  transform: rotateY(180deg);
}

.frontSide::before,
.backSide::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  width: 110%;
  height: 110%;
  position: absolute;
  z-index: -1;
  border-radius: 1em;
  filter: blur(20px);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}



















/* Example CSS for a responsive table */
.crypto-table-container {
  overflow-x: auto;
}

.crypto-table {
  width: 100%;
  border-collapse: collapse;
}

.crypto-table th,
.crypto-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.crypto-table th {
  background-color: #dc01b4;
}

.crypto-table tbody tr:hover {
  background-color: rgba(245, 245, 245, 0.348)
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .crypto-table thead {
    display: none; /* Hide table headers on smaller screens */
  }

  .crypto-table,
  .crypto-table tbody,
  .crypto-table tr,
  .crypto-table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .crypto-table tr {
    margin-bottom: 15px;
    border: none;
  }

  .crypto-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .crypto-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 5px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
  }
}





.info-titleun {
  color: #ff0099;
  font-size: 3.6em;
}
.info-titleunm {
  color: #ff0099;
  font-size: 2.2em;
}


.faqgifz{
  width: 100%;
}

@media screen and (min-width: 1000px){
  .faqgifz{
width: 70%;
  }

}




.faqgifzp{
  width: 80%;
}

@media screen and (min-width: 1000px){
  .faqgifzp{
width: 50%;
  }

}


.linear-wiped {
  text-align: center;
  
  background: linear-gradient(to right, #f6e500 20%, #ff0099 40%,  #ff0099 60%, #FFF 80%);
  background-size: 90% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  animation: shine 1s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

.pixel-buttond {

  position: absolute;
  transform: translateX(-50%);
  font-size: 90px;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(219deg, var(#411b84) 19%, transparent 19%,transparent 20%, var(#9c1569) 20%, var(#9c1569) 39%, transparent 39%,transparent 40%, var(#db0860) 40%,var(#db0860) 59% , transparent 59%,transparent 60%, var(#fa0454) 60%, var(#fa0454) 79%, transparent 79%, transparent 80%, var(#fa0454) 80%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
text-decoration:none !important;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 40px;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
}









.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
color: #93006e;
  font-variant: initial;

  line-height: 1;
}



@media screen and (max-width: 700px){
  .swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    display: none !important;
    letter-spacing: 0;
  color: #93006e;
    font-variant: initial;
    line-height: 1;
  }
  
  
  

}


















.neonText {
  color: #fff;
  text-shadow:
    0 0 7px #8400f8,
    0 0 10px #fff,
    0 0 21px #7b14d4,
    0 0 42px  #7b14d4,
    0 0 82px  #9f8cf7,
    0 0 92px  #7b14d4,
    0 0 102px #7b14d4,
    0 0 151px  #7b14d4
}



.herotitleu{
  color: #FFF;
  text-align: center;
  font-size: 27px;
  line-height: 1.45;
  font-weight: 400;
}


@media screen and (min-width: 1000px){
  .herotitleu{
    color: #FFF;
    text-align: center;
    font-size: 3.75rem;
    line-height: 1;
  
    font-weight: 400;
  }
  
  
}


.centeritallgrid{
text-align: center;
align-items: center;
justify-content: center;

}

.back_of_field{
  border: 1px solid #ff00995c !important;
  background-color: #9f9f9f23 !important;
}













.info-title {
  color: #ff0099;
  font-size: 1em;
}










@media screen and (min-width: 1000px){
  .info-title {
    color: #ff0099;
    font-size: 1.2em;
  }
  
  

}



@keyframes shake {
  0% { transform: translateX(0); }
  10% { transform: translateX(-5px); }
  20% { transform: translateX(1px); }
  30% { transform: translateX(-1px); }
  40% { transform: translateX(1px); }
  50% { transform: translateX(-5px); }
  60% { transform: translateX(1px); }
  70% { transform: translateX(-1px); }
  80% { transform: translateX(1px); }
  90% { transform: translateX(-1px); }
  100% { transform: translateX(0); }
}

.shake-btn {
  display: inline-block;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}